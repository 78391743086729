import React from 'react';
import { Link } from 'gatsby';

import './navigator.scss';

export default function Navigator({ value }) {
  return (
    <ul className="navigator">
      {value.map((item, index) => {
        if (item.external === true) {
          return (
            <li key={index} className="navigator__item">
              <a href={item.url} className="navigator__link" target="_blank" rel="noopener noreferrer nofollow">
                {item.text}
              </a>
            </li>
          );
        }
        return (
          <li key={index} className="navigator__item">
            <Link
              to={item.url}
              className="navigator__link"
              activeClassName="navigator__link--active"
              partiallyActive={item.url === '/blog'}
            >
              {item.text}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
